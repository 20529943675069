.container {
  padding: 20px;
}

.image {
  height: 200px;
  width: auto;
}

.text {
  text-align: justify;
}
