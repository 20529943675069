.container {
  margin-bottom: 100px;
  flex: unset;
}

.services {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  /* grid-template-columns: 1fr 1fr; */
}

.description {
  margin: 20px auto;
  text-align: justify;
  /* padding: 30px; */
  font-size: 18px;
  width: 80%;

}

.title {
  display: block;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #233a82;
  margin: 30px 20px;
  padding-top: 100px;
}

.sub_title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #233a82;
  margin: 20px 20px;
  min-height: 50px;
}

.development {
  padding: 30px;
  /* min-height: 900px; */
  font-size: 18px;
  line-height: 30px;
}

.training {
  padding: 30px;
  height: 100%;
  font-size: 18px;
  line-height: 30px;
}

.training_title {
  font-weight: bold;
  color: black;
}

.list {
  list-style: none;
}

@media screen and (max-width: 1062px) {
  .services {
    grid-template-columns: 1fr;
  }
}
