.container {
    min-height: 150px;
    text-align: center;
}

.title {
    font-weight: bold;
    font-size: 15px;
    color: #233a82;
    margin: 50px 0px;
}

.partners {
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner {
    margin: 0 25px;
}

.dash {
    border-top: 3px solid black;
    width: 50px;
    border-radius: 200px;
}
  
.description {
    text-align: left;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    font-size: 18px;
}

.text {
    width: 80%;
    margin: 0 auto;
}

.list {
    list-style: none;
    /* display: inline-flex; */
}

.list_item {
    margin: 10px 0;
}

.image_container {
    flex: 1;
    text-align: center;
    padding: 2px;
    width: calc(100%/3);
}

.images_group {
    display: flex;
    width: 50%;
    margin: 20px auto;
    text-align: justify;
    font-size: 18px;
    align-items: center;
}

.images_group:nth-child(even) p {
    margin-left: 5%
}

.images_group:nth-child(odd) p {
    margin-right: 5%
}

.image_item {
    overflow: hidden;
    min-width: 20vw;
}

.diagram {
    height: auto;
    width: 100%;
    border: 2px solid;
    border-radius: 10px;
}

.impact {
    height: 25vw;
    width: auto;
}

@media screen and (max-width: 1062px) {
    .images_group {
        display: flex;
        width: unset;
        margin: 20px 5%
    }
    .image_item {
        overflow: hidden;
        min-width: 25vw;
    }
}