.actions {
  display: flex;
  flex-direction: row;
  justify-items: center;
}

.actions > div {
  flex: 1;
}

.checkbox_container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.checkbox_container > div {
  flex: 1;
}
