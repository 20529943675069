.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0px;
}

.image_loader {
  flex: 1;
}

.images {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image_container {
  width: 100%;
  margin: 0px 10px 10px 10px;
  font-size: 20px;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  background: #adcaee;
}

.image_container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  flex: 1;
}
