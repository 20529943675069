.container {
  display: flex;
  flex-direction: column;
  background: #222;
  color: #999;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.slogan_container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.slogan_container p {
  font-size: 12px;
  padding: 10px 10px;
}

.endereco {
  flex: 1;
  padding: 20px;
}

.endereco p {
  font-size: 12px;
  margin: 0px;
}

.logo {
  flex: 1;
  padding: 20px;
}

.logo_image {
  float: right;
  height: 70px;
  margin: 10px 20px;
  width: auto;
}

.slogan {
  flex: 1;
}

.copyright {
  flex: 1;
  text-align: right;
}

.divider {
  flex: 1;
  height: 1px;
  width: 100%;
  border-bottom: solid 2px #4e4e4e;
}
