.title {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: #233a82;
  margin: 100px 0px 50px 0px;
}

.description {
  text-align: left;
  width: 80%;
  margin: 20px auto;
  text-align: justify;
  font-size: 18px;
}

.logo_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.logo {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  min-height: 180px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}

.logo img {
  height: auto;
  width: 200px;
}

.product_info {
  margin-top: 50px;
  min-height: 400px;
  background: #cacaca;
  font-size: 18px;
}

@media screen and (max-width: 1062px) {
  .logo_container {
    flex-direction: column;
  }

  .logo_container img {
    margin: 20px 20px;
  }
}

.unit_container {
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.unit {
  flex: 1;
  min-height: 300px;
  /* max-height: 300px; */
}

.unit div {
  text-align: center;
  text-justify: left;
}

.unit img {
  height: 100px;
  width: auto;
}

.unit span {
  border-radius: 5px;
  padding: 2px;
  margin: 1px;
}

@media screen and (max-width: 1062px) {
  .unit_container {
    flex-direction: column;
  }

  .description {
    padding: 20px 70px;
  }
}