.objective {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 75vh;
  min-height: 560px;
  width: 100%;
  justify-items: center;
  align-items: center;
  /* background-image: url("../../../assets/images/backgroundObjetivo.png");
  background-size: cover; */
}

.circle {
  display: grid;
  justify-items: center;
  align-items: center;
  border: 20px solid #215d9f;
  height: 450px;
  width: 450px;
  border-radius: 50%;
}

.circle > h2 {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 40px;
  font-weight: bold;
  color: #215d9f;
}

.main_title {
  display: block;
  font-weight: bold;
  color: #233a82;
  margin: 20px 20px;
}

.text_titles {
  display: none;
}

.objective_text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  font-size: 18px;
}

.objective_text p {
  margin: 10px 50px;
}

.arrows {
  font-size: 50px;
  text-align: center;
  margin: 30px 0px;
  color: #215d9f;
}

.text_container {
  min-height: 200px;
  max-height: 400px;
  display: grid;
  align-items: center;
  justify-items: center;
}

@media screen and (max-width: 768px) {
  .circle {
    display: none;
  }

  .objective {
    grid-template-columns: 1fr;
  }
}
