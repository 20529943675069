.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
}

.banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100%;
  clip-path: polygon(19% 0, 100% 45%, 100% 53%, 0 100%, 0 100%, 0% 43%, 0 0);
}

.banner_brasillab {
  height: auto;
  width: 50%;
}

.image {
  height: 100vh;
  width: 100%;
  background: #215d9f;
  /* background-image: linear-gradient(to left, #000, #4f4f4f00),
    url("../../../assets/images/main-banner-background.jpg"); */
  background-size: cover;
  background-position: top;
}

.banner_title {
  font-size: 200%;
  padding: 20px;
  color: white;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid #cacaca 1px;
}

.banner_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
}

.banner_image {
  height: 100vh;
  width: 100%;
  opacity: 0.5;
}

.devices_container {
  position: absolute;
  right: 0;
  top: 20%;
}

.devices {
  height: 23vw;
  width: auto;
}

.title {
  position: absolute;
  left: 0px;
  top: 40%;
  padding-left: 20px;
}

.title > h1 {
  color: white;
  font-size: 2.7vw;
}

.brazillab_container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
}

.brazillab {
  height: 150px;
}

@media screen and (max-width: 768px) {
  .banner {
    clip-path: polygon(50% 0, 100% 45%, 100% 53%, 0 100%, 0 100%, 0% 43%, 0 0);
  }

  .devices {
    visibility: hidden;
  }

  .brazillab_container {
    margin-top: 80%;
  }

  .title > h1 {
    font-size: 4vw;
  }
}
