.container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  background: #222;
}

.form_container {
  text-align: center;
  background: #222;
  min-height: 400px;
  min-width: 500px;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #414141;
  padding: 20px;
  border-radius: 10px;
}

.form {
  width: 100%;
}
