.container {
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin: 50px 0px;
}

.form {
  flex: 1;
  width: 40%;
}

.form_container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.form_items {
  flex: 1;
  width: 100%;
  margin: 10px 0px 15px 0px;
}

@media screen and (max-width: 1062px) {
  .form {
    width: 90%;
  }
}
