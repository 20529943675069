.container {
    margin-bottom: 100px;
    min-height: 150px;
    text-align: center;
}

.title {
    font-weight: bold;
    font-size: 15px;
    color: #233a82;
    margin: 50px 0px;
}
  
.description {
    text-align: left;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    font-size: 18px;
}

.text {
    width: 80%;
    margin: 0 auto;
}

.list {
    list-style: none;
}

.image_container {
    padding: 1% 0;
    width: 400px;
}

.images_group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
    align-items: center;
    justify-items: center;
    /* width: 50%; */
    margin-right: 10%;
    margin-left: 10%;
}

.diagram {
    border: 2px solid;
    border-radius: 10px;
    /* height: 300px; */
    height: auto;
    width: 100%;
} 

/* .image_container {
    flex: 1;
    text-align: center;
    padding: 0 2px;
} */

/* .images_group {
    margin-top: 50px;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    flex-wrap: wrap;
    list-style: none;
    overflow-y: auto;
    background: #424242;
    margin-right: 25%;
    margin-left: 25%;
} */

/* .image_item {
    overflow: hidden;
}

.diagram {
    height: 15vw;
    min-width: 100%;
} */

.highlight8 {
    width: 500px;
    /* grid-column: 1 / span 2; */
}

@media screen and (max-width: 1062px) {
    .image_container {
        padding: 1% 0;
        width: 200px;
    }
    .images_group {
        grid-template-columns: 1fr;
        margin-right: 10%;
        margin-left: 10%;
    }
    .diagram {
        min-width: 100%;
    }
    .highlight10 {
        /* grid-column: 1; */
        width: 350px;
    }
    .highlight8 {
        /* grid-column: 1; */
        width: 350px;
    }
}

/* @media screen and (max-width: 1062px) {
    .images_group {
        margin-right: 10%;
        margin-left: 10%;
    }
    .diagram {
        height: 25vw;
        min-width: 100%;
    }
} */