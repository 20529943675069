.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo_container {
  flex: 1;
  margin: 20px;
}

.logo {
  height: 80px;
  width: auto;
}

.items {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: 100%;
  text-align: center;
  margin-bottom: auto;
}

.items > li {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin: 5px;
  border-bottom: 1px solid #515151;
  border-top: 1px solid #515151;
}

.items > li:hover {
  background: rgb(138, 138, 138);
}

.items > li > a,
.items > li > a:hover {
  flex: 1;
  color: white;
  text-decoration: none;
}

.actions {
  flex: 1;
  cursor: pointer;
  color: white;
  margin-bottom: 20px;
}

.actions:hover {
  color: black;
}
