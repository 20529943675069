.company {
  min-height: 150px;
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 15px;
  color: #233a82;
  margin: 50px 0px;
}

.description {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  font-size: 18px;
}

.pillar {
  display: flex;
  width: 80%;
  margin: 20px auto;
  text-align: justify;
  font-size: 18px;
  align-items: center;
}

.pillar h3 {
  min-width: 5em;
  max-width: 5em;
  margin: 0px;
  text-align: left !important;
  font-weight: bold;
  font-size: 18px;
  color: #233a82;
}