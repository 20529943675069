.container {
  float: unset
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #233a82;
  margin: 50px 0px;
}

.unit_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 400px;
  align-items: stretch;
}

.description {
  text-align: justify !important;
  padding: 0px 20px;
  font-size: 16px;
}

.description h3 {
  text-align: center !important;
  margin: 10px 0px;
  font-weight: bold;
  font-size: 18px;
  color: #233a82;
}

.unit {
  flex: 1;
  min-height: 300px;
  /* max-height: 300px; */
}

.unit div {
  text-align: center;
  text-justify: left;
}

.unit img {
  height: 100px;
  width: auto;
}

@media screen and (max-width: 1062px) {
  .unit_container {
    flex-direction: column;
  }

  .description {
    padding: 20px 70px;
  }
}
