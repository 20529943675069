.container {
  min-height: 50vh;
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30px 1fr;
  border: 1px solid rgb(202, 202, 202);
}

.toolbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(202, 202, 202);
  flex-direction: row;
  list-style: none;
  padding: 0px 10px;
}

.toolbar > li {
  flex: 1;
  text-align: center;
  max-width: 100px;
  cursor: pointer;
  border-radius: 10px;
}

.toolbar > li:hover {
  background: rgb(196, 196, 196);
  color: white;
}

.editor {
  background: white;
  padding: 10px;
}
