.links {
  text-decoration: none !important;
  color: black;
  font-weight: bold;
}

.links:hover {
  color: black;
}

.links_mobile {
  text-decoration: none !important;
  color: white;
  font-weight: bold;
}

.links_mobile:hover {
  color: white;
}

.links_active {
  text-decoration: none !important;
  color: black;
  font-weight: bold;
}

.links_active:hover {
  color: black;
}

.is_current {
  border-bottom: 3px solid #215d9f;
}

.nav_container {
  z-index: 999;
  position: fixed;
  width: 100%;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  min-height: 8vh;
  background: rgba(0, 0, 0, 0);
}

.nav_active {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  min-height: 8vh;
  background: black;
  border-bottom: 1px solid #ededed;
}

.nav_logo {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr;
  justify-items: center;
  align-items: center;
  height: 60px;
  width: auto;
  margin-top: 5px;
  cursor: pointer;
}

.nav_items,
.nav_items_mob {
  cursor: pointer;
  font-size: 10px;
  list-style: none;
  display: flex;
  width: 65%;
  justify-content: space-around;
}

.nav_items li {
  color: black;
  letter-spacing: 1px;
}

.nav_items_mob li {
  color: white;
  letter-spacing: 1px;
}

.nav_items_active {
  cursor: pointer;
  font-size: 10px;
  list-style: none;
  display: flex;
  width: 65%;
  justify-content: space-around;
}

.nav_items_mob_active {
  color: white;
}

.nav_items_active li {
  color: black;
  letter-spacing: 1px;
}

.nav_items_mob_active li {
  color: white;
  letter-spacing: 1px;
}

.nav_items_mob {
  display: none;
}

.burguer {
  display: none;
  cursor: pointer;
}

.burguer div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
}

.burguer_active div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
}

.icons {
  color: white;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
}

.icons > a {
  color: white;
  text-decoration: none !important;
}

.icons_active {
  color: black;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
}

.icons_active > a {
  color: black;
  text-decoration: none !important;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .nav_items {
    display: none;
  }

  .nav_items_mob {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 10vh;
    background-color: #383737;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    color: white;
  }

  .burguer {
    display: block;
  }

  .nav_items_active {
    display: none;
  }

  .nav_items_mob_active {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: #383737;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    color: white;
  }

  .burguer_active {
    display: block;
  }
}
