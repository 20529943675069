.container {
  display: grid;
  justify-items: center;
  height: 200px;
  margin: 30px;
  background: rgb(101, 101, 101);
  border-radius: 5px;
  text-align: center;
}

.media_preview {
  height: 100px;
  width: auto;
  margin-top: 10px;
}

.indicators_container {
  color: white;
  background: rgb(26, 188, 76);
  width: 100%;
}

.preview_container {
  background: rgb(66, 66, 66);
  width: 100%;
  border-radius: 5px;
}

.actions {
  padding: 10px;
  background: rgb(121, 121, 121);
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
