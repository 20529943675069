.container {
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr 10fr;
  grid-template-rows: 100%;
}

.sidenav {
  background: #222;
}

.main {
  background: #ececec;
  padding: 20px;
  overflow-y: scroll;
}
