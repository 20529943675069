.container {
  display: flex;
  flex-direction: column;
}

.articles_container {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.pagination_container {
  flex: 1;
}
