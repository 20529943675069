.container {
    background: #000000b9;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: justify;
    padding: 20px;
}

.text {
    width: 80%;
    margin: 0 auto;
}

.list {
    list-style: none;
}

.image_container {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.diagram {
    height: 25vw;
    width: auto;
}

.impact {
    height: 25vw;
    width: auto;
}

@media screen and (max-width: 1062px) {
    .diagram {
        height: 25vw;
        width: auto;
    }
    
    .impact {
        height: 45vw;
        width: auto;
    }
}