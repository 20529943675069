.container {
    background: #26af6b;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: justify;
    padding: 20px;
}

.title {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    width: 50%;
    color: #254441;
    margin: 0px 0% 0px 25%;
  }

.text {
    width: 80%;
    margin: 0 auto;
}

.list {
    list-style: none;
}

.image_container {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.diagram {
    height: 15vw;
    width: auto;
}

.impact {
    height: 25vw;
    width: auto;
}

@media screen and (max-width: 1062px) {
    .diagram {
        height: 25vw;
        width: auto;
    }
    
    .impact {
        height: 45vw;
        width: auto;
    }
}