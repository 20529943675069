.container {
  margin-bottom: 100px;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #233a82;
  margin: 50px 0px;
}

.clients_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.private {
  flex: 1;
  text-align: center;
}

.public {
  flex: 1;
  text-align: center;
}

.clients {
  display: flex;
  flex-direction: row;
}

.client {
  flex: 1;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease
}
.client:hover {
  /* border: 2px solid; */
  background: #eee;
}

.divider {
  height: 400px;
  width: 1px;
  border-left: 1px solid #afafaf;
}

.dummy {
  display: block;
}

@media screen and (max-width: 1062px) {
  .clients_container {
    grid-template-columns: 1fr;
  }

  .dummy,
  .divider {
    display: none;
  }

  .clients {
    display: grid;
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 20px;
    margin: 60px 0px;
  }
}
